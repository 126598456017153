import { default as _91_46_46_46token_93BJkSttGoU7Meta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization-social/[...token].vue?macro=true";
import { default as indexuaGQprPIoWMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization/index.vue?macro=true";
import { default as indexcXMx2ndOqIMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/api/index.vue?macro=true";
import { default as indexWDsxIyiJYrMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/index.vue?macro=true";
import { default as indexxqcr0UhyiOMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexlCblZErTh2Meta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/payments/index.vue?macro=true";
import { default as index3MzPWqccbvMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/profile/index.vue?macro=true";
import { default as index9YJOni1P8yMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/forgot-password/index.vue?macro=true";
import { default as index7oQLfVormoMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/index.vue?macro=true";
import { default as indexzTh18u08EOMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/payment/result/index.vue?macro=true";
import { default as indexbuWT1WeouBMeta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_93j12atK3WO8Meta } from "/var/www/smscode-frontend/smscode-front-20241112111510/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93BJkSttGoU7Meta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93BJkSttGoU7Meta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93BJkSttGoU7Meta || {},
    alias: _91_46_46_46token_93BJkSttGoU7Meta?.alias || [],
    redirect: _91_46_46_46token_93BJkSttGoU7Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93BJkSttGoU7Meta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93BJkSttGoU7Meta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93BJkSttGoU7Meta || {},
    alias: _91_46_46_46token_93BJkSttGoU7Meta?.alias || [],
    redirect: _91_46_46_46token_93BJkSttGoU7Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93BJkSttGoU7Meta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93BJkSttGoU7Meta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93BJkSttGoU7Meta || {},
    alias: _91_46_46_46token_93BJkSttGoU7Meta?.alias || [],
    redirect: _91_46_46_46token_93BJkSttGoU7Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: indexuaGQprPIoWMeta?.name ?? "authorization___en___default",
    path: indexuaGQprPIoWMeta?.path ?? "/authorization",
    meta: indexuaGQprPIoWMeta || {},
    alias: indexuaGQprPIoWMeta?.alias || [],
    redirect: indexuaGQprPIoWMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexuaGQprPIoWMeta?.name ?? "authorization___en",
    path: indexuaGQprPIoWMeta?.path ?? "/en/authorization",
    meta: indexuaGQprPIoWMeta || {},
    alias: indexuaGQprPIoWMeta?.alias || [],
    redirect: indexuaGQprPIoWMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexuaGQprPIoWMeta?.name ?? "authorization___ru",
    path: indexuaGQprPIoWMeta?.path ?? "/ru/authorization",
    meta: indexuaGQprPIoWMeta || {},
    alias: indexuaGQprPIoWMeta?.alias || [],
    redirect: indexuaGQprPIoWMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexcXMx2ndOqIMeta?.name ?? "cabinet-api___en___default",
    path: indexcXMx2ndOqIMeta?.path ?? "/cabinet/api",
    meta: indexcXMx2ndOqIMeta || {},
    alias: indexcXMx2ndOqIMeta?.alias || [],
    redirect: indexcXMx2ndOqIMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexcXMx2ndOqIMeta?.name ?? "cabinet-api___en",
    path: indexcXMx2ndOqIMeta?.path ?? "/en/cabinet/api",
    meta: indexcXMx2ndOqIMeta || {},
    alias: indexcXMx2ndOqIMeta?.alias || [],
    redirect: indexcXMx2ndOqIMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexcXMx2ndOqIMeta?.name ?? "cabinet-api___ru",
    path: indexcXMx2ndOqIMeta?.path ?? "/ru/cabinet/api",
    meta: indexcXMx2ndOqIMeta || {},
    alias: indexcXMx2ndOqIMeta?.alias || [],
    redirect: indexcXMx2ndOqIMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexWDsxIyiJYrMeta?.name ?? "cabinet___en___default",
    path: indexWDsxIyiJYrMeta?.path ?? "/cabinet",
    meta: indexWDsxIyiJYrMeta || {},
    alias: indexWDsxIyiJYrMeta?.alias || [],
    redirect: indexWDsxIyiJYrMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexWDsxIyiJYrMeta?.name ?? "cabinet___en",
    path: indexWDsxIyiJYrMeta?.path ?? "/en/cabinet",
    meta: indexWDsxIyiJYrMeta || {},
    alias: indexWDsxIyiJYrMeta?.alias || [],
    redirect: indexWDsxIyiJYrMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexWDsxIyiJYrMeta?.name ?? "cabinet___ru",
    path: indexWDsxIyiJYrMeta?.path ?? "/ru/cabinet",
    meta: indexWDsxIyiJYrMeta || {},
    alias: indexWDsxIyiJYrMeta?.alias || [],
    redirect: indexWDsxIyiJYrMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexxqcr0UhyiOMeta?.name ?? "cabinet-numbers-history___en___default",
    path: indexxqcr0UhyiOMeta?.path ?? "/cabinet/numbers-history",
    meta: indexxqcr0UhyiOMeta || {},
    alias: indexxqcr0UhyiOMeta?.alias || [],
    redirect: indexxqcr0UhyiOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexxqcr0UhyiOMeta?.name ?? "cabinet-numbers-history___en",
    path: indexxqcr0UhyiOMeta?.path ?? "/en/cabinet/numbers-history",
    meta: indexxqcr0UhyiOMeta || {},
    alias: indexxqcr0UhyiOMeta?.alias || [],
    redirect: indexxqcr0UhyiOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexxqcr0UhyiOMeta?.name ?? "cabinet-numbers-history___ru",
    path: indexxqcr0UhyiOMeta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexxqcr0UhyiOMeta || {},
    alias: indexxqcr0UhyiOMeta?.alias || [],
    redirect: indexxqcr0UhyiOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexlCblZErTh2Meta?.name ?? "cabinet-payments___en___default",
    path: indexlCblZErTh2Meta?.path ?? "/cabinet/payments",
    meta: indexlCblZErTh2Meta || {},
    alias: indexlCblZErTh2Meta?.alias || [],
    redirect: indexlCblZErTh2Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexlCblZErTh2Meta?.name ?? "cabinet-payments___en",
    path: indexlCblZErTh2Meta?.path ?? "/en/cabinet/payments",
    meta: indexlCblZErTh2Meta || {},
    alias: indexlCblZErTh2Meta?.alias || [],
    redirect: indexlCblZErTh2Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexlCblZErTh2Meta?.name ?? "cabinet-payments___ru",
    path: indexlCblZErTh2Meta?.path ?? "/ru/cabinet/payments",
    meta: indexlCblZErTh2Meta || {},
    alias: indexlCblZErTh2Meta?.alias || [],
    redirect: indexlCblZErTh2Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index3MzPWqccbvMeta?.name ?? "cabinet-profile___en___default",
    path: index3MzPWqccbvMeta?.path ?? "/cabinet/profile",
    meta: index3MzPWqccbvMeta || {},
    alias: index3MzPWqccbvMeta?.alias || [],
    redirect: index3MzPWqccbvMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index3MzPWqccbvMeta?.name ?? "cabinet-profile___en",
    path: index3MzPWqccbvMeta?.path ?? "/en/cabinet/profile",
    meta: index3MzPWqccbvMeta || {},
    alias: index3MzPWqccbvMeta?.alias || [],
    redirect: index3MzPWqccbvMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index3MzPWqccbvMeta?.name ?? "cabinet-profile___ru",
    path: index3MzPWqccbvMeta?.path ?? "/ru/cabinet/profile",
    meta: index3MzPWqccbvMeta || {},
    alias: index3MzPWqccbvMeta?.alias || [],
    redirect: index3MzPWqccbvMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index9YJOni1P8yMeta?.name ?? "forgot-password___en___default",
    path: index9YJOni1P8yMeta?.path ?? "/forgot-password",
    meta: index9YJOni1P8yMeta || {},
    alias: index9YJOni1P8yMeta?.alias || [],
    redirect: index9YJOni1P8yMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index9YJOni1P8yMeta?.name ?? "forgot-password___en",
    path: index9YJOni1P8yMeta?.path ?? "/en/forgot-password",
    meta: index9YJOni1P8yMeta || {},
    alias: index9YJOni1P8yMeta?.alias || [],
    redirect: index9YJOni1P8yMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index9YJOni1P8yMeta?.name ?? "forgot-password___ru",
    path: index9YJOni1P8yMeta?.path ?? "/ru/forgot-password",
    meta: index9YJOni1P8yMeta || {},
    alias: index9YJOni1P8yMeta?.alias || [],
    redirect: index9YJOni1P8yMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index7oQLfVormoMeta?.name ?? "index___en___default",
    path: index7oQLfVormoMeta?.path ?? "/",
    meta: index7oQLfVormoMeta || {},
    alias: index7oQLfVormoMeta?.alias || [],
    redirect: index7oQLfVormoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7oQLfVormoMeta?.name ?? "index___en",
    path: index7oQLfVormoMeta?.path ?? "/en",
    meta: index7oQLfVormoMeta || {},
    alias: index7oQLfVormoMeta?.alias || [],
    redirect: index7oQLfVormoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7oQLfVormoMeta?.name ?? "index___ru",
    path: index7oQLfVormoMeta?.path ?? "/ru",
    meta: index7oQLfVormoMeta || {},
    alias: index7oQLfVormoMeta?.alias || [],
    redirect: index7oQLfVormoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexzTh18u08EOMeta?.name ?? "payment-result___en___default",
    path: indexzTh18u08EOMeta?.path ?? "/payment/result",
    meta: indexzTh18u08EOMeta || {},
    alias: indexzTh18u08EOMeta?.alias || [],
    redirect: indexzTh18u08EOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexzTh18u08EOMeta?.name ?? "payment-result___en",
    path: indexzTh18u08EOMeta?.path ?? "/en/payment/result",
    meta: indexzTh18u08EOMeta || {},
    alias: indexzTh18u08EOMeta?.alias || [],
    redirect: indexzTh18u08EOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexzTh18u08EOMeta?.name ?? "payment-result___ru",
    path: indexzTh18u08EOMeta?.path ?? "/ru/payment/result",
    meta: indexzTh18u08EOMeta || {},
    alias: indexzTh18u08EOMeta?.alias || [],
    redirect: indexzTh18u08EOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexbuWT1WeouBMeta?.name ?? "registration___en___default",
    path: indexbuWT1WeouBMeta?.path ?? "/registration",
    meta: indexbuWT1WeouBMeta || {},
    alias: indexbuWT1WeouBMeta?.alias || [],
    redirect: indexbuWT1WeouBMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexbuWT1WeouBMeta?.name ?? "registration___en",
    path: indexbuWT1WeouBMeta?.path ?? "/en/registration",
    meta: indexbuWT1WeouBMeta || {},
    alias: indexbuWT1WeouBMeta?.alias || [],
    redirect: indexbuWT1WeouBMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexbuWT1WeouBMeta?.name ?? "registration___ru",
    path: indexbuWT1WeouBMeta?.path ?? "/ru/registration",
    meta: indexbuWT1WeouBMeta || {},
    alias: indexbuWT1WeouBMeta?.alias || [],
    redirect: indexbuWT1WeouBMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93j12atK3WO8Meta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_93j12atK3WO8Meta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93j12atK3WO8Meta || {},
    alias: _91_46_46_46token_93j12atK3WO8Meta?.alias || [],
    redirect: _91_46_46_46token_93j12atK3WO8Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93j12atK3WO8Meta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_93j12atK3WO8Meta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93j12atK3WO8Meta || {},
    alias: _91_46_46_46token_93j12atK3WO8Meta?.alias || [],
    redirect: _91_46_46_46token_93j12atK3WO8Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93j12atK3WO8Meta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_93j12atK3WO8Meta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93j12atK3WO8Meta || {},
    alias: _91_46_46_46token_93j12atK3WO8Meta?.alias || [],
    redirect: _91_46_46_46token_93j12atK3WO8Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241112111510/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]